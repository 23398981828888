<template>
<div>
  <Row :gutter="10">
    <Col span="24" style="text-align: right">
      <Button type="primary" @click="exportsExcel()">导出</Button>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="action" slot-scope="{row}">
          <Button style="margin-left: 5px" type="primary" @click="detail(row)">查看</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
export default {
  name: "PaperSubmit",
  data: function(){
    return {
      searchKey: {
        page: 1,
        pagesize: 30,
        paper_id: ''
      },
      list: [],
      columns: [],
      total: 0,

    }
  },
  methods: {
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    exportsExcel: function(){
      this.$http.get(api.paperSubmitExport, {params: {id: this.searchKey.paper_id}}).then(response => {
        window.location.href = response.data.path;
        // console.log(response)
      })
    },
    getList: function(){
      this.$http.get(api.paperSubmitList, {params: {id:this.searchKey.paper_id}}).then(response => {
        if(response.data.success) {
          for(let item of response.data.res.form) {
            if(item.paper_form_type === 'upload' || item.paper_form_type === 'textarea') continue;
            this.columns.push({title: item.paper_form_name, key: item.paper_form_field})
          }
          this.columns.push({title: '操作', slot: 'action', width: 120})
          this.list = response.data.res.table;
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    detail: function(row){
      const paper_table_id = 'paper_table_' + this.searchKey.paper_id + '_id';
      this.$router.push({path: '/xiaochengxu/paper/submitdetail', query: {paper_id: this.searchKey.paper_id, paper_table_id: row[paper_table_id]}})
    }
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/paper');
    this.searchKey.paper_id = this.$route.query.id
    this.getList();
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>